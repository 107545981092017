<!--电费充值记录-->
<template>
  <table-view class="charging-fee-record" :total="total" :query-info.sync="queryInfo"
              v-loading="loading"
              @reload-table="renderTable">
    <template #header>
      <view-search-form :queryInfo.sync="queryInfo" keyword-placeholder="姓名、宿舍、订单编号" :tool-list="['keyword']"
        :filter-keys="['roomId', 'collegeId']" style="display: inline-block" @on-search="renderTable(1)">
        <el-form-item label="支付状态" prop="status">
          <tool-tips-group is-flex tips-content="充值电费有2步，充值到建行 和 充值到电费账户：<br/>
                            待支付：刚下单，但没支付<br/> 支付失败：一般不会有，备用<br/>
                             已支付待充值电费：支付成功，但还没发送充值电费请求<br/>
                              已发送充值电费请求：支付成功，已发送充值电费请求<br/>
                               充值电费失败：电费充值失败(<b style='color: var(--color-danger)'>也有可能是一直未支付，超时过期，作为失效订单，失效时间为30分钟</b>)<br />
                                充值电费成功：电费充值成功">
            <el-select v-model="queryInfo.status" clearable size="small" @clear="handleClear(queryInfo, 'status')">
              <el-option v-for="{ paramValue, paramName } in electricPayStatusList" :key="paramValue" :label="paramName"
                :value="Number(paramValue)" />
            </el-select>
          </tool-tips-group>
        </el-form-item>
        <!--        <el-form-item label="订单状态" prop="ordrStcd">-->
        <!--          <el-select v-model="queryInfo.ordrStcd" clearable size="small">-->
        <!--            <el-option-->
        <!--              v-for="{paramValue,paramName}  in ordrStcdList"-->
        <!--              :key="paramValue"-->
        <!--              :label="paramName"-->
        <!--              :value="paramValue" />-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
      </view-search-form>
    </template>
    <el-table :data="tableData" style="width: 100%;" :height="tableHeight" border stripe>
      <el-table-column type="index" label="ID" width="50" align="center" />
      <el-table-column prop="orderNo" label="订单编号" min-width="230" />
      <el-table-column prop="userName" label="姓名" width="100" align="center" />
      <el-table-column label="宿舍" min-width="200">
        <template v-slot="{ row }">
          <span v-if="row.dormitoryName && row.roomName">{{ row.dormitoryName }}{{ row.roomName }}室</span>
        </template>
      </el-table-column>
      <el-table-column label="充值金额(元)" prop="amount" width="120" align="center" />
      <el-table-column label="充值度数" prop="degreesNum" width="110" align="center" />
      <el-table-column label="支付时间" prop="payTime" width="180" align="center" />
      <el-table-column label="订单状态" width="140" fixed="right">
        <template v-slot="{ row }">
          {{ row.status ? $http.getParamName(row.status, electricPayStatusList) : '--' }}
        </template>
      </el-table-column>
      <el-table-column label="支付状态" width="140" fixed="right">
        <template v-slot="{ row }">
          {{ row.ordrStcd ? $http.getParamName(row.ordrStcd, ordrStcdList) : '--' }}
        </template>
      </el-table-column>
    </el-table>
  </table-view>
</template>
<script>
import tableView from '@/vue/mixins/table-view'
import { pageChargingFeeRecord } from '@/api/finance-manage/charging-fee-record-api'

export default {
  name: 'chargingFeeRecord',
  mixins: [tableView],
  props: {
    regionId: String,
    id: String
  },
  data() {
    return {
      isMeterDialog: false, // 是否为水电表模块的电费记录弹窗
      electricPayStatusList: [], // 支付状态
      ordrStcdList: [], // 订单状态
      queryInfo: {
        collegeId: null,
        roomId: null,
        status: null,
        ordrStcd: null
      },
      tableHeight: '530px'
    }
  },
  watch: {
    $route: {
      handler(to) {
        // 判断是否
        if (to.path === '/repair/electricMeterManage') {
          this.isMeterDialog = true
          this.queryInfo.status = 6
          this.queryInfo.collegeId = null
          this.tableHeight = '530px'
        } else {
          this.isMeterDialog = false
          this.queryInfo.collegeId = this.$store.state.login.userInfo.collegeId || null
          this.queryInfo.status = null
          this.tableHeight = null
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    await Promise.all([
      this.$http.getParamListByKey('electricOrdrStcd', this, 'ordrStcdList'),
      this.$http.getParamListByKey('electricPayStatus', this, 'electricPayStatusList')
    ])
  },
  methods: {
    async renderTable(pageNum) {
      if (this.isMeterDialog) {
        this.queryInfo.collegeId = null
        if (this.id) this.queryInfo.roomId = this.id
      } else {
        this.queryInfo.collegeId = this.$store.state.login.userInfo.collegeId || null
        this.queryInfo.roomId = null
      }
      await this.getTableData(pageChargingFeeRecord, pageNum)
    }
  }
}
</script>
